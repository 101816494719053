import TryDemoBottom from "../components/shared/TryDemoBottom";
import { UseCasesDetailsItem } from "../components/shared/UseCasesSections";

const UseCasesDetails = () => {
  return (
    <>
      <UseCasesDetailsItem />
      <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      />
    </>
  );
};

export default UseCasesDetails;
