import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import ListSquareItems from "../components/shared/ListSquareItems";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: " Redundancy and Failover",
    title: " Redundancy and Failover",
    img: CHECK,
    imageType: "gif",
    info: `Rely on our redundant infrastructure to prevent disruptions to your digital signage. Benefit from failover mechanisms that guarantee your screens stay active, even in the event of a server failure. `,
  },
  {
    id: "Scalability ",
    title: " Scalability",
    img: CHECK,
    imageType: "gif",
    info: `Easily scale your digital signage network with our cloud infrastructure. Add new screens or locations without worrying about infrastructure limitations, ensuring smooth expansion as your business grows. `,
  },
];

const CloudInfrastructure = () => {
  return (
    <>
      <PageTitle
        title={"Cloud Infrastructure"}
        // subTitle={"Home - Features - Cloud Infrastructure"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>
            Reliable and Scalable Cloud Infrastructure for Uninterrupted
            Operations
          </h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            With Shasha, your digital signage is powered by a robust cloud
            infrastructure, ensuring seamless performance and scalability.
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={true} rowNum={2} />
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default CloudInfrastructure;
