import { Link } from "react-router-dom";
import PLAYERS from "../../assets/images/Logos 466x256.png";

import Box from "../shared/Box";
import { AnimationFadeIn, AnimationFadeInUp } from "../shared/Animations";

const PlayerOptions = () => {
  return (
    <section className="player_options">
      <div className="player_options_text">
        <AnimationFadeInUp>
          <h2>Shasha Player Options</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            We partnered up with onsign.tv to use their Player App with to
            enhance our clients’ experience with Shasha.{" "}
          </p>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <Link to={"/players"} className="light_link">
            Download
          </Link>
        </AnimationFadeInUp>
      </div>

      <div className="player_options_imgs">
        <AnimationFadeIn>
          {" "}
          <img src={PLAYERS} alt="players" />
        </AnimationFadeIn>
      </div>
    </section>
  );
};

export default PlayerOptions;
