import { AnimatedOnScroll } from "react-animated-css-onscroll";
export function AnimationFadeInUp({ children }) {
  return (
    <div style={{ overflow: "hidden" }}>
      <AnimatedOnScroll animationIn="animate__fadeInUp ">
        {children}
      </AnimatedOnScroll>
    </div>
  );
}

export function AnimationFadeIn({ children }) {
  return (
    <div>
      <AnimatedOnScroll animationIn="animate__fadeIn ">
        {children}
      </AnimatedOnScroll>
    </div>
  );
}
export function AnimationFadeLeft({ children }) {
  return (
    <div style={{ overflow: "hidden" }}>
      <AnimatedOnScroll animationIn="animate__fadeInLeft ">
        {children}
      </AnimatedOnScroll>
    </div>
  );
}
