import { useSelector } from "react-redux";
import BACK1 from "../../assets/icons/Group 427319790.webp";
import BACK2 from "../../assets/icons/Vector.webp";
import BACK3 from "../../assets/icons/Group 427319792.webp";
import BACK4 from "../../assets/icons/Vector2.webp";
import BACK5 from "../../assets/icons/Group 427319794.webp";
import BACK6 from "../../assets/icons/Vector2.webp";
import BACK7 from "../../assets/icons/Group 427319794.webp";
import ARROW_RIGHT from "../../assets/icons/arrow-right-colored.webp";
import "../../styles/shared.scss";
import { Link } from "react-router-dom";
import CenterTitles from "./CenterTitles";
import Box from "./Box";
import { useState } from "react";
import { AnimationFadeInUp, AnimationFadeLeft } from "./Animations";

const list = [
  {
    title: "Access the Cloud-Based Platform",
    full_info:
      "Our digital signage content management platform runs entirely in the cloud.",
    info: "Our digital signage content management platform runs entirely in the cloud. This means you can access and manage your displays anytime, anywhere - all you need is a web browser. Our servers are secure and scalable, and they're built with full redundancy. This ensures your screens are always on, delivering your message without interruption.",
    image: (
      <div className="h_i_w_b_img">
        <div className="h_i_w_b_i">
          <p>1</p>
          <img src={BACK1} alt="back style" />
        </div>
        {/* <div className="h_i_w_arrow">
          <img src={BACK2} alt="back style" />
        </div> */}
      </div>
    ),
  },
  {
    title: "Manage Your Content ",
    full_info:
      "Once you're on the platform, you can upload, edit, and manage your content with a few clicks.",
    info: "Once you're on the platform, you can upload, edit, and manage your content with a few clicks. Our user-friendly interface makes it easy even for those with no technical background.",
    image: (
      <div className="h_i_w_b_img">
        <div className="h_i_w_b_i">
          <p>2</p>
          <img src={BACK3} alt="back style" />
        </div>
        {/* <div className="h_i_w_arrow">
          <img src={BACK4} alt="back style" />
        </div> */}
      </div>
    ),
  },
  {
    title: "Choose Your Player ",
    full_info:
      "To present your content on your screens, you'll need a small computer or a 'Player.'",
    info: "To present your content on your screens, you'll need a small computer or a 'Player.' We're proud to say that we are compatible with the largest list of hardware in the market. This means you have a wide array of options to choose from to best suit your needs.",
    image: (
      <div className="h_i_w_b_img">
        <div className="h_i_w_b_i">
          <p>3</p>
          <img src={BACK5} alt="back style" />
        </div>
        {/* <div className="h_i_w_arrow">
          <img src={BACK6} alt="back style" />
        </div> */}
      </div>
    ),
  },
  {
    title: " Publish and Monitor    ",
    full_info:
      "Once your content is ready and your Player is set, you can publish your content to your screens.",
    info: "Once your content is ready and your Player is set, you can publish your content to your screens. You can also monitor the status and performance of your screens right from the platform, ensuring everything runs smoothly.",
    image: (
      <div className="h_i_w_b_img">
        <div className="h_i_w_b_i">
          <p>4</p>
          <img src={BACK7} alt="back style" />
        </div>
      </div>
    ),
  },
];

const HowItWorksSection = ({ title, info, seeMore }) => {
  const { content } = useSelector((state) => state.content);
  const [popup, setPopup] = useState({});

  return (
    <Box className="how_it_works">
      {/* {popup.title && (
        <section className="feature_popup">
          <div className="feature_popup_item  ">
            <div className="feature_popup_item_top">
              <div className="feature_popup_item_top_con">
                {popup.image}

                <h3>{popup.title}</h3>
              </div>
              <svg
                onClick={() => setPopup({})}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_334_760)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0007 13.4144L17.6575 19.0713C18.048 19.4618 18.6812 19.4618 19.0717 19.0713C19.4622 18.6807 19.4622 18.0476 19.0717 17.657L13.4149 12.0002L19.0717 6.34335C19.4622 5.95283 19.4622 5.31966 19.0717 4.92914C18.6812 4.53861 18.048 4.53861 17.6575 4.92914L12.0007 10.586L6.34382 4.92912C5.9533 4.5386 5.32013 4.5386 4.92961 4.92912C4.53909 5.31965 4.53909 5.95281 4.92961 6.34334L10.5864 12.0002L4.92961 17.6571C4.53909 18.0476 4.53909 18.6807 4.92961 19.0713C5.32013 19.4618 5.9533 19.4618 6.34382 19.0713L12.0007 13.4144Z"
                    fill="#010101"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_760">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p>{popup.full_info}</p>
          </div>
        </section>
      )} */}

      <CenterTitles title={title} description={info} />

      <div className="h_i_w_bottomInfo">
        <div className="h_i_w_b_text">
          <div className="h_i_w_b_text_item">
            <AnimationFadeLeft>{list[0].image}</AnimationFadeLeft>
            <AnimationFadeLeft>
              <div className="h_i_w_b_t">
                <p className="title">{list[0].title}</p>
                <p>{list[0].info}</p>
              </div>
            </AnimationFadeLeft>
            {/* <AnimationFadeLeft>
              <button onClick={() => setPopup(list[0])}>
                See More <img src={ARROW_RIGHT} alt="right arrow" />
              </button>
            </AnimationFadeLeft> */}
          </div>

          <div className="h_i_w_b_text_item">
            <AnimationFadeLeft>{list[1].image}</AnimationFadeLeft>
            <AnimationFadeLeft>
              <div className="h_i_w_b_t">
                <p className="title"> {list[1].title}</p>
                <p>{list[1].info}</p>
              </div>
            </AnimationFadeLeft>
            {/* <AnimationFadeLeft>
              <button onClick={() => setPopup(list[1])}>
                See More <img src={ARROW_RIGHT} alt="right arrow" />
              </button>
            </AnimationFadeLeft> */}
          </div>

          <div className="h_i_w_b_text_item">
            <AnimationFadeLeft>{list[2].image}</AnimationFadeLeft>
            <AnimationFadeLeft>
              <div className="h_i_w_b_t">
                <p className="title"> {list[2].title}</p>
                <p>{list[2].info}</p>
              </div>
            </AnimationFadeLeft>
            {/* <AnimationFadeLeft>
              <button onClick={() => setPopup(list[2])}>
                See More <img src={ARROW_RIGHT} alt="right arrow" />
              </button>
            </AnimationFadeLeft> */}
          </div>

          <div className="h_i_w_b_text_item">
            <AnimationFadeLeft>{list[3].image}</AnimationFadeLeft>
            <AnimationFadeLeft>
              <div className="h_i_w_b_t">
                <p className="title"> {list[3].title}</p>
                <p>{list[3].info}</p>
              </div>
            </AnimationFadeLeft>
            {/* <AnimationFadeLeft>
              <button onClick={() => setPopup(list[3])}>
                See More <img src={ARROW_RIGHT} alt="right arrow" />
              </button>
            </AnimationFadeLeft> */}
          </div>
        </div>
      </div>
      {seeMore && (
        <div
          className="light_link_see"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <AnimationFadeInUp>
            <Link to="/how-it-works" className="light_link">
              See More
            </Link>
          </AnimationFadeInUp>
        </div>
      )}
    </Box>
  );
};

export default HowItWorksSection;
