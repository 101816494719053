import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import CHECK2 from "../assets/icons/shield.gif";
import CHECK3 from "../assets/icons/wrench.gif";
import CHECK4 from "../assets/icons/circle-loader.gif";
import CHECK5 from "../assets/icons/cloud-download.gif";
import ListSquareItems from "../components/shared/ListSquareItems";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: " Secure Authentication",
    title: " Secure Authentication",
    img: CHECK2,
    imageType: "gif",
    info: ` Safeguard your account with secure authentication protocols. Implement two-factor authentication (2FA) and password policies to ensure only authorized users can access and manage your digital signage content`,
  },
  {
    id: " User Access Control",
    title: " User Access Control",
    img: CHECK3,
    imageType: "gif",
    info: ` Manage user roles and permissions to control access to specific features and functionalities within the platform. Define user privileges and assign appropriate permissions to maintain content integrity`,
  },
  {
    id: "Content Encryption ",
    title: "Content Encryption ",
    img: CHECK4,
    imageType: "gif",
    info: ` Protect your content from unauthorized access or tampering with robust encryption algorithms. Rest easy knowing that your valuable information is safeguarded against potential threats.`,
  },
  {
    id: "Secure Cloud Storage ",
    title: " Secure Cloud Storage",
    img: CHECK5,
    imageType: "gif",
    info: `Leverage the power of our secure cloud infrastructure to store and manage your digital signage content. Benefit from industry-leading data protection measures and resilient backup strategies. `,
  },
];

const Security = () => {
  return (
    <>
      <PageTitle
        title={"Security"}

        // subTitle={"Home - Features - Security"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Protect Your Content and Data with Robust Security Measures</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            At Shasha, we prioritize the security of your digital signage
            infrastructure and content. Our comprehensive security features
            ensure that your data remains safe and your displays are protected
            from unauthorized access. Trust in our commitment to security.
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={true} rowNum={2} />
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default Security;
