import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import ListSquareItems from "../components/shared/ListSquareItems";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Geographical Targeting ",
    title: " Geographical Targeting",
    img: CHECK,
    imageType: "gif",
    info: ` Assign specific geographic tags to your screens, allowing you to deliver targeted messages to specific regions, cities, or even individual locations. Increase relevance and engagement by delivering content that resonates with the local audience.
    `,
  },
  {
    id: "Location-Based Offers ",
    title: " Location-Based Offers",
    img: CHECK,
    imageType: "gif",
    info: ` Leverage geo tagging to promote location-specific offers or promotions. Deliver personalized messages to viewers based on their proximity to your stores or venues, driving foot traffic and boosting conversions.`,
  },
  {
    id: "Regionalized Content ",
    title: " Regionalized Content",
    img: CHECK,
    imageType: "gif",
    info: ` Tailor your content to cater to regional preferences or cultural nuances. With geo tagging, you can create customized campaigns that resonate with specific audiences, ensuring maximum impact.`,
  },
];

const GeoTagging = () => {
  return (
    <>
      <PageTitle
        title={"Geo Tagging"}
        // subTitle={"Home - Features - Geo Tagging"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2> Deliver Targeted Messages with Geo Tagging</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Reach your audience with relevant and location-specific content
            through our geo tagging feature. Tailor your digital signage
            campaigns based on the geographic location of your screens.
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={true} rowNum={3} />
      <Box className="page_description">
        <AnimationFadeInUp>
          <p>
            By harnessing the power of content publishing/unpublishing and geo
            tagging, you can strategically deploy targeted messages and ensure
            your content is relevant to your viewers' locations. Take advantage
            of these features to deliver personalized experiences and drive
            engagement.
          </p>
        </AnimationFadeInUp>
        <br />
        <AnimationFadeInUp>
          <p>
            Join Shasha today and empower your digital signage strategy with
            seamless content deployment and location-specific messaging. Start
            your free trial or request a demo now and witness the transformative
            power of our content management software.
          </p>
        </AnimationFadeInUp>
      </Box>
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default GeoTagging;
