import Box from "../components/shared/Box";
import ListSquareItems from "../components/shared/ListSquareItems";
import PageTitle from "../components/shared/PageTitle";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Drag-and-Drop Editor",
    title: "Drag-and-Drop Editor",
    img: CHECK,
    imageType: "gif",
    info: `Design captivating layouts and customize your content with our intuitive drag-and-drop editor. No coding or design skills required. Effortlessly arrange images, videos, text, and other media elements to create visually impactful displays`,
  },
  {
    id: "Multimedia Support",
    title: "Multimedia Support",
    img: CHECK,
    imageType: "gif",
    info: `Enrich your content with a variety of multimedia formats. Embed videos, display image galleries, incorporate audio, and even integrate live social media feeds to captivate your audience and deliver dynamic experiences`,
  },
  {
    id: "Templates and Themes",
    title: "Templates and Themes",
    img: CHECK,
    imageType: "gif",
    info: `Jumpstart your content creation process with our extensive library of templates and themes. Choose from a wide range of professionally designed options, easily customize them to fit your brand, and save time while ensuring a visually consistent display`,
  },
  {
    id: "Scheduling and Playlists",
    title: "Scheduling and Playlists",
    img: CHECK,
    imageType: "gif",
    info: `Effortlessly manage your content schedule and playlists. Schedule content to play at specific times, days, or recurring intervals, ensuring that the right message reaches your audience at the right time`,
  },
  {
    id: "Data Integration",
    title: "Data Integration",
    img: CHECK,
    imageType: "gif",
    info: `Integrate your data sources, such as CRM or POS systems, to display dynamic and personalized content. Showcase real-time sales data, social media metrics, or other data-driven visuals to create a personalized and relevant experience`,
  },
];

const Content = () => {
  return (
    <>
      <PageTitle
        title={"Content"}
        // subTitle={"Home - Features - Content"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Create Captivating Content with Ease</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            At Shasha, we believe that compelling content is the cornerstone of
            an effective digital signage strategy. That's why our content
            management software provides you with powerful tools to create
            visually stunning and engaging displays. Take your content to new
            heights with our innovative features.
          </p>
        </AnimationFadeInUp>
      </Box>

      <ListSquareItems list={list} icons={true} rowNum={3} />

      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default Content;
