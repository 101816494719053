import { Link } from "react-router-dom";
import Box from "../shared/Box";
import CenterTitles from "../shared/CenterTitles";
import PricingInfo from "../shared/PricingInfo";
import { AnimationFadeInUp } from "../shared/Animations";

const HomePricing = () => {
  return (
    <Box className="home_pricing">
      <div className="home_pricing_Info">
        <AnimationFadeInUp>
          <h2>Pricing</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>Flexible payment plans to fulfill your demand</p>
        </AnimationFadeInUp>
      </div>
      <PricingInfo
        pro={"Do it yourself."}
        premium={"Get tailored features with a dedicated account manager."}
      />
      <AnimationFadeInUp>
        <Link to="/pricing" className="light_link" style={{ margin: "auto" }}>
          More Details
        </Link>
      </AnimationFadeInUp>
    </Box>
  );
};

export default HomePricing;
