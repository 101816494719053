import PageTitle from "../components/shared/PageTitle";
import PricingInfo from "../components/shared/PricingInfo";

const Pricing = () => {
  return (
    <>
      <PageTitle title={"Pricing"} subTitle={""} />
      <PricingInfo
        table={true}
        pro="Seamlessly centralize and manage your organization's signage efforts with our online do it yourself solution.
Create your account and try it out yourself today."
        premium="Let us manage your signage efforts features with additional tailored features and a dedicated account manager who can fully manage your signage efforts depending on your needs. You can consider us an outsourced extension of your capabilities supporting your team with the day to day operations."
      />
    </>
  );
};

export default Pricing;
