import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import ListSquareItems from "../components/shared/ListSquareItems";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Touchscreen Support ",
    title: " Touchscreen Support",
    img: CHECK,
    imageType: "gif",
    info: ` Enable touch capabilities on compatible displays to allow viewers to interact directly with your content. Create immersive experiences, interactive directories, or engaging games to captivate your audience.
    `,
  },
  {
    id: " Mobile Integration",
    title: " Mobile Integration",
    img: CHECK,
    imageType: "gif",
    info: `Enable viewers to interact with your displays using their mobile devices. Utilize QR codes, NFC, or other mobile technologies to provide seamless experiences and drive customer engagement.
    `,
  },
];

const Interactivity = () => {
  return (
    <>
      <PageTitle
        title={"Interactivity"}
        // subTitle={"Home - Features - Interactivity"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Engage Your Audience with Interactive Experiences</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Captivate and interact with your audience using our innovative
            interactivity features.
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={true} rowNum={2} />
      {/* <TryDemoBottom
        content={`Experience the peace of mind that comes with robust security measures, seamless integrations, reliable cloud infrastructure, remote player monitoring and management, and captivating interactivity. Join Shasha today, and revolutionize your digital signage strategy. Start your free trial or request a demo now!
`}
        background={true}
      /> */}
    </>
  );
};
export default Interactivity;
