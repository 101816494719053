import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import ListSquareItems from "../components/shared/ListSquareItems";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Open API",
    title: " Open API",
    img: CHECK,
    imageType: "gif",
    info: `Leverage our Open API to integrate with third-party applications, customize workflows, and extend the functionality of your digital signage platform. Build unique solutions tailored to your specific needs `,
  },
  {
    id: "Content Feeds ",
    title: "Content Feeds ",
    img: CHECK,
    imageType: "gif",
    info: ` Integrate external content feeds such as news, social media, weather, or RSS feeds to dynamically populate your displays with up-to-date and relevant information`,
  },
  {
    id: "Data Integration ",
    title: "Data Integration ",
    img: CHECK,
    imageType: "gif",
    info: `Connect your CRM, POS, or other data sources to display real-time data-driven content. Deliver personalized messages, showcase sales metrics, or leverage customer information for targeted campaigns `,
  },
];

const Integrations = () => {
  return (
    <>
      <PageTitle
        title={"API & Integrations"}
        // subTitle={"Home - Features - API & Integrations"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Extend and Customize Your Digital Signage Capabilities</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Our digital signage content management software is designed to
            integrate seamlessly with your existing systems and workflows.
            Unlock endless possibilities with our powerful API and integrations.
          </p>
        </AnimationFadeInUp>
      </Box>

      <ListSquareItems list={list} icons={true} rowNum={3} />
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default Integrations;
