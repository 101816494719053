import HomeHeader from "../components/homepage/HomeHeader";
import PlayerOptions from "../components/homepage/PlayerOptions";
import PowerfulApps from "../components/homepage/PowerfulApps";
import UnlockThePower from "../components/homepage/UnlockThePower";
import WhatIsShasha from "../components/homepage/WhatIsShasha";
import HowItWorksSection from "../components/shared/HowItWorksSection";
import ImageTextSection from "../components/shared/ImageTextSection";
import BACK2 from "../assets/images/pexels-danedelions-1120872.jpg";
import BACK1 from "../assets/images/homeimage3.jpg";
import HomePricing from "../components/homepage/HomePricing";
import { UseCasesSlide } from "../components/shared/UseCasesSections";

const HomePage = () => {
  return (
    <section className="homepage">
      <HomeHeader />
      <div id="what-is-shasha">
        <WhatIsShasha />
      </div>
      <HowItWorksSection
        seeMore={true}
        title={`How It Works`}
        info={`Here at Shasha, we believe that managing your digital signage should be as straightforward and reliable as possible. Our platform is designed with this principle at its core. Here's how it works:`}
      />
      <UseCasesSlide />
      <UnlockThePower />
      <PowerfulApps />
      <PlayerOptions />
      <ImageTextSection
        background="#f8f8f8"
        title={"Start Your Free Trial"}
        info={
          "Our digital signage content management software has been meticulously designed to deliver that power into your hands. With an intuitive interface, cutting-edge features, and robust performance, managing your digital signage has never been easier or more effective.  "
        }
        link1={{ href: "/contact", text: "Try For Free" }}
        link2={{ href: "/contact", text: "Request a Demo " }}
        image={BACK1}
        order={"left"}
        border={true}
      />
      <HomePricing />
      <div className="how_bottom">
        <ImageTextSection
          background="#ededed"
          title={"Get Started"}
          info={
            "Our digital signage content management software has been meticulously designed to deliver that power into your hands. "
          }
          link1={{ href: "/contact", text: "Start Your Free Trial Today" }}
          link2={{ href: "/contact", text: "Request a Demo" }}
          image={BACK2}
          border={true}
        />
      </div>
    </section>
  );
};

export default HomePage;
