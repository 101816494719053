import Box from "../components/shared/Box";
import ListSquareItems from "../components/shared/ListSquareItems";
import PageTitle from "../components/shared/PageTitle";

import report1 from "../assets/images/report1.webp";
import report2 from "../assets/images/report2.webp";
import report3 from "../assets/images/report3.webp";
import report4 from "../assets/images/reportsp ic2.jpg";
import report5 from "../assets/images/reports pisc.jpg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Performance Metrics",
    title: "Performance Metrics",
    img: report1,
    info: `Track and analyze the performance of your content with detailed metrics. Gain insights into audience engagement, impressions, and interactions to measure the effectiveness of your displays`,
  },
  {
    id: "Content Popularity",
    title: "Content Popularity",
    img: report2,
    info: `Identify your most popular content and understand what resonates with your audience. Analyze views, interactions, and feedback to optimize your content strategy and deliver more impactful messages`,
  },
  {
    id: "Audience Analytics",
    title: "Audience Analytics",
    img: report3,
    info: `Dig deeper into your audience demographics and behavior with advanced analytics. Discover who your viewers are, how long they engage with your content, and tailor your displays to maximize relevance and engagement`,
  },
  {
    id: "Proof of Play",
    title: "Proof of Play",
    img: report4,
    info: `Ensure the compliance and effectiveness of your content with proof of play reports. Verify that your content is being displayed correctly and on schedule, providing you with peace of mind and actionable insights`,
  },
  {
    id: "A/B Testing",
    title: "A/B Testing",
    img: report5,
    info: `Experiment and compare different content variations with A/B testing. Measure the performance of different messages, layouts, or visuals to refine your content strategy and optimize engagement`,
  },
];
const Reports = () => {
  return (
    <>
      <PageTitle
        title={"Reports"}
        // subTitle={"Home - Features - Reports"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Measure, Analyze, and Optimize Your Success</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Understanding the impact of your digital signage campaigns is
            crucial for driving continuous improvement and achieving your goals.
            Our content management software provides comprehensive reporting and
            analytics tools to help you gain valuable insights and make
            data-driven decisions.
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={false} rowNum={3} />

      <Box className="page_description">
        <AnimationFadeInUp>
          <p>
            By leveraging our robust reporting capabilities, you can gain a
            comprehensive understanding of your digital signage performance.
            Armed with these insights, you can make data-driven decisions to
            optimize your content strategy and achieve your business objectives.
          </p>
        </AnimationFadeInUp>
        <br />
        <AnimationFadeInUp>
          <p>
            Unlock the power of captivating content and data-driven
            decision-making with Shasha. Start your free trial or request a demo
            today and experience the transformative impact of our content
            management software.
          </p>
        </AnimationFadeInUp>
      </Box>
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default Reports;
