import Box from "../shared/Box";
import CenterTitles from "../shared/CenterTitles";
import FeaturesList from "../shared/FeaturesList";
import FEATURE_GREEN from "../../assets/icons/feature_Green.webp";
import FEATURE_BLUE from "../../assets/icons/feature_Blue.webp";
import FEATURE_ORANGE from "../../assets/icons/feature_Orange.webp";
import FEATURE_TEAL from "../../assets/icons/feature_Teal.webp";
import FEATURE_YELLOW from "../../assets/icons/feature_Yellow.webp";

const items = [
  {
    id: "Drag-and-Drop Content Editor",
    title: "Drag-and-Drop Content Editor",
    description: "Effortlessly design stunning displays with..",
    full_description:
      "Effortlessly design stunning displays with our intuitive drag-and-drop editor. No coding or design skills required. Customize layouts, add text, images, videos, and even social media feeds to create visually captivating content.",
    img: FEATURE_GREEN,
  },
  {
    id: "Real-Time Updates",
    title: "Real-Time Updates",
    description: "Keep your content fresh ..",
    full_description:
      "Keep your content fresh and relevant with real-time updates. Make instant changes to your displays from anywhere, ensuring your messages are always up to date and aligned with your business needs.",
    img: FEATURE_BLUE,
  },
  {
    id: "Multi-Platform Compatibility",
    title: "Multi-Platform Compatibility",
    description: "Our software supports a wide ..",
    full_description:
      "Our software supports a wide range of hardware, giving you the flexibility to choose the player that fits your requirements and budget. Easily integrate your preferred hardware and enjoy seamless functionality.",
    img: FEATURE_ORANGE,
  },
  {
    id: "Scheduling & Targeting",
    title: "Scheduling & Targeting",
    description: "Deliver the right message ..",
    full_description:
      "Deliver the right message to the right audience at the right time. Utilize our advanced scheduling and targeting features to display content based on specific dates, times, or locations. Tailor your messages for maximum impact.",
    img: FEATURE_YELLOW,
  },
  {
    id: "Remote Monitoring & Control",
    title: "Remote Monitoring & Control",
    description: "Monitor and manage your ..",
    full_description:
      "Monitor and manage your screens remotely with ease. Our software allows you to oversee content playback, screen status, and troubleshoot issues from a centralized dashboard, saving you time and effort.",
    img: FEATURE_TEAL,
  },
];

const UnlockThePower = () => {
  return (
    <Box className="unlock_the_power">
      <CenterTitles
        title={"Unlock the Power of Digital Signage with Shasha's Features"}
        description={
          "Our feature-rich digital signage content management software is designed to empower businesses of all sizes. We have the tools you need to create engaging and impactful displays. Here are some of the key features that set us apart:"
        }
      />

      <FeaturesList list={items} allLink={true} />
    </Box>
  );
};

export default UnlockThePower;
