import { useSelector } from "react-redux";
import IMAGE from "../../assets/images/pexels-andrea-piacquadio-3760608-1.webp";
import Box from "../shared/Box";
import { AnimationFadeIn, AnimationFadeInUp } from "../shared/Animations";
import PrimaryBTN from "../shared/PrimaryBTN";
import { Link } from "react-router-dom";

const WhatIsShasha = () => {
  const { content } = useSelector((state) => state.content);

  return (
    <Box className="what_is_shasha">
      <section className="w_i_s_text">
        <AnimationFadeInUp>
          <h2>{content.text6}</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Shasha is a content management system "CMS" enabling you to manage
            all your digital signage efforts through a centralised platform.
          </p>
        </AnimationFadeInUp>
        <br />
        <AnimationFadeInUp>
          <p>
            With an intuitive interface, cutting-edge features, and robust
            performance, managing your digital signage has never been easier or
            more effective. Explore the possibilities and revolutionize your
            business communication with Shasha.
          </p>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <div className="h_h_t_links">
            <PrimaryBTN path="/contact" content={content.text3} />
            <Link
              style={{ background: "#dadaff", color: "#051d8d" }}
              to={"/contact"}
            >
              {content.text4}
            </Link>
          </div>
        </AnimationFadeInUp>
      </section>
      <AnimationFadeIn>
        {" "}
        <section className="w_i_s_img">
          <div className="w_i_s_img_con">
            <img
              style={{ transform: "scaleX(-1)" }}
              src={IMAGE}
              alt="what is shasha"
            />
          </div>
        </section>
      </AnimationFadeIn>
    </Box>
  );
};

export default WhatIsShasha;
