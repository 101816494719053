import { AnimationFadeIn, AnimationFadeInUp } from "./Animations";

const ListSquareItems = ({ list, icons, rowNum }) => {
  return (
    <section className="list_square_items">
      {list &&
        list.map((item) => (
          <div
            key={item.id}
            className="l_s_item"
            style={{ width: `calc(${100 / rowNum}% - 2.5rem)` }}
          >
            <div
              className={`${!icons && "img_full_width"} ${
                item.imageType === "gif" && "gif_icons"
              }`}
            >
              <AnimationFadeIn>
                <img src={item.img} alt={item.title} />
              </AnimationFadeIn>
            </div>
            <div className="l_s_i_text">
              <AnimationFadeInUp>
                <h3>{item.title}</h3>
              </AnimationFadeInUp>
              <AnimationFadeInUp>
                <p>{item.info}</p>
              </AnimationFadeInUp>
            </div>
          </div>
        ))}
    </section>
  );
};

export default ListSquareItems;
