import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrimaryBTN from "../shared/PrimaryBTN";
import "../../styles/homepage.scss";
import ARROW_DOWN from "../../assets/icons/arrow-down.webp";
import { AnimationFadeInUp } from "../shared/Animations";

const HomeHeader = () => {
  const { content } = useSelector((state) => state.content);

  return (
    <header className="home_header">
      <section className="home_header_text">
        <AnimationFadeInUp>
          <h1>{content.text1}</h1>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Effortless, scalable, reliable; unleash the power of your screens!
          </p>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <div className="h_h_t_links">
            <PrimaryBTN path="/contact" content={content.text3} />
            <Link to={"/contact"}>{content.text4}</Link>
          </div>
        </AnimationFadeInUp>
      </section>

      <section className="scroll_down">
        <a href="/#what-is-shasha" style={{ textDecoration: "none" }}>
          <button>
            {content.text5}
            <img src={ARROW_DOWN} alt="arrow down" />
          </button>
        </a>
      </section>
    </header>
  );
};

export default HomeHeader;
