import { Link } from "react-router-dom";
import CHECK from "../../assets/icons/Cheak.webp";
import { useState } from "react";
import { AnimationFadeInUp, AnimationFadeLeft } from "./Animations";

const PricingInfo = ({ table, pro, premium }) => {
  const [monthly, setMonthly] = useState("monthly");
  const monthlyPrice = 19;
  const data = {
    monthly: {
      price: 20 ,
      priceText: ["Per license"],
    },
    yearly: {
      price: 215,
      priceText: ["Per license"],
    },
  };

  return (
    <section className="pricing_info">
      <AnimationFadeInUp>
        <div className="toggle_year">
          <p> Monthly</p>
          <div
            style={{ justifyContent: monthly == "monthly" ? "flex-start" : "" }}
            className="price_toggle"
            onClick={() => {
              setMonthly(monthly !== "monthly" ? "monthly" : "yearly");
            }}
          >
            <p></p>
          </div>
          <p>Annually</p>
        </div>
      </AnimationFadeInUp>
      <AnimationFadeInUp>
        <div className="pricing_card">
          <div className="pricing_card_item active">
            <div>
              <h3>Shasha</h3>
              <br />
              <p>{pro}</p>
            </div>
            <div className="pricing_card_count">
              <h2>${data[monthly].price}</h2>
              <p>
                <span>{data[monthly].priceText[0]}</span>
                <span>{data[monthly].priceText[1]}</span>
              </p>
            </div>
            <div className="pricing_card_count">
              <h3>
                <Link to={"/contact"} style={{ color: "#fff" }}>
                  Get Started
                </Link>
              </h3>
            </div>
          </div>

          <div className="pricing_card_item">
            <div>
              <h3>Shasha+</h3>
              <br />
              <p>{premium}</p>
            </div>
            <br />
            <div className="pricing_card_count">
              <h3>
                <Link to={"/contact"}>Get Quote</Link>
              </h3>
            </div>
          </div>
        </div>
      </AnimationFadeInUp>
      <br />
      <br />
      <br />
      {/* {table && (
        <div className="pricing_list">
          <div className="pricing_list_item">
            <p className="pricing_list_item_title">Get all features</p>
            <p>Unlimited</p>
            <p>Unlimited</p>
            <p>Unlimited</p>
          </div>

          <div className="pricing_list_item">
            <p className="pricing_list_item_title">Get all features</p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.0188 3.53003C9.37353 3.53003 3.17578 9.40495 3.17578 16.652C3.17578 23.8991 9.37353 29.774 17.0188 29.774C24.6641 29.774 30.8619 23.8991 30.8619 16.652C30.8619 9.40495 24.6641 3.53003 17.0188 3.53003ZM23.4423 11.7074C24.0296 12.1714 24.109 12.9988 23.6195 13.5555L16.698 21.4287C16.4563 21.7036 16.1067 21.873 15.7301 21.8977C15.3536 21.9225 14.9827 21.8003 14.7034 21.5596L10.5504 17.9809C9.98473 17.4934 9.94304 16.6635 10.4573 16.1273C10.9716 15.591 11.8471 15.5515 12.4128 16.039L15.4963 18.6962L21.4926 11.8754C21.9821 11.3187 22.8549 11.2435 23.4423 11.7074Z"
                  fill="#051D8D"
                />
              </svg>
            </p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.0188 3.53003C9.37353 3.53003 3.17578 9.40495 3.17578 16.652C3.17578 23.8991 9.37353 29.774 17.0188 29.774C24.6641 29.774 30.8619 23.8991 30.8619 16.652C30.8619 9.40495 24.6641 3.53003 17.0188 3.53003ZM23.4423 11.7074C24.0296 12.1714 24.109 12.9988 23.6195 13.5555L16.698 21.4287C16.4563 21.7036 16.1067 21.873 15.7301 21.8977C15.3536 21.9225 14.9827 21.8003 14.7034 21.5596L10.5504 17.9809C9.98473 17.4934 9.94304 16.6635 10.4573 16.1273C10.9716 15.591 11.8471 15.5515 12.4128 16.039L15.4963 18.6962L21.4926 11.8754C21.9821 11.3187 22.8549 11.2435 23.4423 11.7074Z"
                  fill="#051D8D"
                />
              </svg>
            </p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.0188 3.53003C9.37353 3.53003 3.17578 9.40495 3.17578 16.652C3.17578 23.8991 9.37353 29.774 17.0188 29.774C24.6641 29.774 30.8619 23.8991 30.8619 16.652C30.8619 9.40495 24.6641 3.53003 17.0188 3.53003ZM23.4423 11.7074C24.0296 12.1714 24.109 12.9988 23.6195 13.5555L16.698 21.4287C16.4563 21.7036 16.1067 21.873 15.7301 21.8977C15.3536 21.9225 14.9827 21.8003 14.7034 21.5596L10.5504 17.9809C9.98473 17.4934 9.94304 16.6635 10.4573 16.1273C10.9716 15.591 11.8471 15.5515 12.4128 16.039L15.4963 18.6962L21.4926 11.8754C21.9821 11.3187 22.8549 11.2435 23.4423 11.7074Z"
                  fill="#051D8D"
                />
              </svg>
            </p>
          </div>

          <div className="pricing_list_item">
            <p className="pricing_list_item_title">Get all features</p>
            <p>Unlimited</p>
            <p>Unlimited</p>
            <p>Unlimited</p>
          </div>

          <Link href="/contact">Get Started</Link>
        </div>
      )} */}
    </section>
  );
};

export default PricingInfo;
