import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import HowItWorks from "./pages/HowItWorks";
import { useDispatch, useSelector } from "react-redux";
import contentSlice from "./store/contentSlice";
import { useLocation } from "react-router-dom";
import Features from "./pages/Features";
import AppsPage from "./pages/AppsPage";
import Players from "./pages/Players";
import Content from "./pages/Content";
import Reports from "./pages/Reports";
import Security from "./pages/Security";
import Integrations from "./pages/Integrations";
import CloudInfrastructure from "./pages/CloudInfrastructure";
import MonitoringManagement from "./pages/MonitoringManagement";
import Interactivity from "./pages/Interactivity";
import PublishingUnpublishing from "./pages/PublishingUnpublishing";
import GeoTagging from "./pages/GeoTagging";
import Pricing from "./pages/Pricing";
import UseCases from "./pages/UseCases";
import UseCasesDetails from "./pages/UseCasesDetails";
import Footer from "./components/footer/Footer";
import NavBar from "./components/navbar/NavBar";
import ScrollToTop from "./components/ScrollToTop";
import Contact from "./pages/Contact";
import "./App.css";

const App = () => {
  const dispatch = useDispatch();
  const { addContent } = contentSlice.actions;
  const location = useLocation();
  const { content } = useSelector((state) => state.content);

  useEffect(() => {
    const fetchCSVData = async (fileName) => {
      const response = await fetch(fileName);
      const text = await response.text();
      const newObj = {};
      // Parse CSV data
      const parsedData = text.split("\n").map((row) => row.split(";"));

      for (let x of parsedData) {
        if (x[0]) {
          newObj[x[0]] = x[1];
        }
      }
      dispatch(addContent(newObj));
    };
    if (location.pathname === "/") {
      fetchCSVData("./data/homepage.csv");
    } else {
      fetchCSVData(`./data/${location.pathname.split(1)}.csv`);
    }
  }, [location.pathname]);

  return (
    <div>
      {Object.keys(content).length > 0 && (
        <div className="app">
          {/* <Loading /> */}
          <div>
            <ScrollToTop />
            <NavBar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/key-features" element={<Features />} />
              <Route path="/use-cases" element={<UseCases />} />
              <Route path="/use-cases-details" element={<UseCasesDetails />} />
              <Route path="/players" element={<Players />} />

              {/* <Route path="/apps" element={<AppsPage />} />
            <Route path="/content" element={<Content />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/security" element={<Security />} />
            <Route path="/api-integrations" element={<Integrations />} />
            <Route
            path="/cloud-infrastructure"
            element={<CloudInfrastructure />}
            />
            <Route
            path="/remote-player-monitoring-and-management"
            element={<MonitoringManagement />}
            />
            <Route path="/interactivity" element={<Interactivity />} />
            <Route
            path="/content-publishing-unpublishing"
            element={<PublishingUnpublishing />}
            />
          <Route path="/geo-tagging" element={<GeoTagging />} /> */}
            </Routes>
          </div>
          <div className="app_footer">
            <Footer />{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;

const Loading = ({ children }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let counter;
    counter = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(counter);
  });

  return <>{loading && <div className="loading"></div>}</>;
};
