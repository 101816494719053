import { Link } from "react-router-dom";
import Apps from "../shared/Apps";
import Box from "../shared/Box";
import CenterTitles from "../shared/CenterTitles";
import { AnimationFadeInUp } from "../shared/Animations";

const PowerfulApps = () => {
  return (
    <Box className={"powerful_apps"}>
      <CenterTitles
        title={`Enhance Your Digital Signage Experience with Powerful Apps`}
        description={`At Shasha, we understand that every business has unique needs and objectives when it comes to digital signage. That's why we offer a diverse range of apps designed to amplify the impact of your displays. Explore our selection of powerful apps and take your digital signage to the next level`}
      />

      <Apps />
      <AnimationFadeInUp>
        <Link to="/key-features#apps" className="light_link">
          More Details
        </Link>
      </AnimationFadeInUp>
    </Box>
  );
};

export default PowerfulApps;
