import { AnimationFadeInUp } from "../components/shared/Animations";
import Apps from "../components/shared/Apps";
import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import TryDemoBottom from "../components/shared/TryDemoBottom";

const AppsPage = () => {
  return (
    <>
      <PageTitle
        title={"Apps"}
        // subTitle={"Home - Features - Apps"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Enhance Your Digital Signage Experience with Powerful Apps</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            At Shasha, we understand that every business has unique needs and
            objectives when it comes to digital signage. That's why we offer a
            diverse range of apps designed to amplify the impact of your
            displays. Explore our selection of powerful apps and take your
            digital signage to the next level.
          </p>
        </AnimationFadeInUp>
      </Box>

      <Apps />

      <Box className="page_description pad">
        <AnimationFadeInUp>
          <p>
            These are just a few examples of the powerful apps available with
            our digital signage content management software. Each app is
            designed to enhance specific aspects of your digital signage
            strategy, giving you the flexibility to customize and optimize your
            displays according to your unique requirements.
          </p>
        </AnimationFadeInUp>
      </Box>

      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};

export default AppsPage;
