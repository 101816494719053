import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import ListSquareItems from "../components/shared/ListSquareItems";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Real-Time Status Monitoring ",
    title: "Real-Time Status Monitoring ",
    img: CHECK,
    imageType: "gif",
    info: `Track the status of your screens and players in real-time. Receive instant notifications for connectivity issues, content playback errors, or other player-related events, allowing for swift troubleshooting `,
  },
  {
    id: " Content Scheduling and Updates",
    title: " Content Scheduling and Updates",
    img: CHECK,
    imageType: "gif",
    info: ` Effortlessly schedule and update content remotely. Push new campaigns, promotions, or emergency messages to your screens in seconds, ensuring your displays are always up-to-date.
    `,
  },
];

const MonitoringManagement = () => {
  return (
    <>
      <PageTitle
        title={"Remote Player Monitoring and Management"}
        // subTitle={"Home - Features - Remote Player Monitoring and Management"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>
            Effortless Control and Monitoring of Your Digital Signage Network
          </h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            Manage and monitor your digital signage screens from anywhere in the
            world with our remote player monitoring and management capabilities.
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={true} rowNum={2} />
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default MonitoringManagement;
