import { Link } from "react-router-dom";
import "../../styles/shared.scss";
import RIGHT_ARROW from "../../assets/icons/arrow-right.webp";

const PrimaryBTN = ({ path, content }) => {
  return (
    <Link className="primaryBTN" to={path}>
      {content}
      <img src={RIGHT_ARROW} alt="right icon" />
    </Link>
  );
};

export default PrimaryBTN;
