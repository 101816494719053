import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import "../styles/contact.scss";
import ARROW_RIGHT from "../assets/icons/arrow-right.webp";
import BACK from "../assets/images/bg.webp";
import MAP from "../assets/images/Rectangle 4885.webp";
import {
  AnimationFadeIn,
  AnimationFadeInUp,
  AnimationFadeLeft,
} from "../components/shared/Animations";

const Contact = () => {
  return (
    <>
      {/* <PageTitle title={"Contact Us"} subTitle={" "} />
      <ContactItems /> */}
      {/* <Box className={"contact_margin"}></Box> */}
      <AnimationFadeIn>
        <Box className={"contact_form"}>
          <div className="contact_img">
            <img src={BACK} alt="contact back" />
          </div>

          <form
            target="_self"
            action="https://formspree.io/f/mblrjkwr"
            method="POST"
          >
            {/* <p>Keep in touch</p> */}
            <h2>Get Started</h2>
            <div>
              <input type="text" name="Name" placeholder="Name" required />
              <input
                type="text"
                name="Company_Name"
                placeholder="Company Name"
                required
              />
              <input
                type="email"
                name="Email_Address"
                placeholder="Email Address"
                required
              />
              <textarea placeholder="Message" name="Message"></textarea>
              <section className="form_radio">
                <label for="age1">
                  <input
                    type="radio"
                    id="age1"
                    name="type"
                    required
                    value="Demo"
                  />
                  Demo
                </label>
                <br />
                <label for="age2">
                  <input
                    type="radio"
                    id="age2"
                    name="type"
                    required
                    value="Free Trial"
                  />
                  Free Trial
                </label>
              </section>
              <button type=" submit">
                Submit <img src={ARROW_RIGHT} alt="" />
              </button>
            </div>
          </form>
        </Box>
      </AnimationFadeIn>
      <AnimationFadeIn>
        <section className="contact_map">
          <img src={MAP} alt="map" />

          <a href="https://maps.app.goo.gl/tWUoJ6Aj2mpZ9SoYA?g_st=iw">
            <div className="contact_map_text">
              <p>10 9WC 523, Dubai Free-zone, Dubai, UAE.</p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_356_2278)">
                    <path
                      d="M11.3492 22H9.60317V15.5834C9.60317 13.0167 7.76984 11 5.4127 11H3.23016L6.02381 14.025L4.80159 15.3084L0 10.0834L4.80159 4.85836L6.02381 6.14169L3.23016 9.16669H5.4127C7.07143 9.16669 8.55556 9.90002 9.60317 11.0917V11C9.60317 7.42502 12.2222 4.58336 15.5397 4.58336H17.7222L14.9286 1.55836L16.1508 0.275024L20.9524 5.50002L16.1508 10.725L14.9286 9.44169L17.7222 6.41669H15.5397C13.1825 6.41669 11.3492 8.43336 11.3492 11V22Z"
                      fill="#051D8D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_356_2278">
                      <rect width="20.9524" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <strong>Directions</strong>
              </p>

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="30"
                  viewBox="0 0 29 30"
                  fill="none"
                >
                  <g clip-path="url(#clip0_356_2268)">
                    <path
                      d="M14.2866 2.5C20.2039 2.5 25.0008 7.5368 25.0008 13.75C25.0008 17.5922 23.0057 20.7365 20.9037 22.9931C19.7504 24.2311 18.5242 25.2466 17.4791 26.0141L16.9727 26.3771C16.8912 26.4341 16.8113 26.4894 16.7331 26.5428L16.2852 26.8424L15.8852 27.0989L15.3902 27.4012C14.7007 27.8101 13.8724 27.8101 13.1829 27.4012L12.6879 27.0989L12.0695 26.698C11.9948 26.6481 11.9183 26.5963 11.84 26.5428L11.3514 26.2008C10.2537 25.4144 8.91877 24.3343 7.66943 22.9931C5.56738 20.7365 3.57227 17.5922 3.57227 13.75C3.57227 7.5368 8.36922 2.5 14.2866 2.5ZM14.2866 10C12.3141 10 10.7151 11.6789 10.7151 13.75C10.7151 15.8211 12.3141 17.5 14.2866 17.5C16.2591 17.5 17.858 15.8211 17.858 13.75C17.858 11.6789 16.2591 10 14.2866 10Z"
                      fill="#051D8D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_356_2268">
                      <rect width="28.5714" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </a>
        </section>
      </AnimationFadeIn>
    </>
  );
};

export default Contact;

const ContactItems = () => {
  return (
    <div className="contact_items">
      <div className="contact_items_con">
        {/* <div className="contact_i">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_356_2400)">
              <path
                d="M23.8881 16.9201V19.9201C23.8892 20.1986 23.8322 20.4743 23.7206 20.7294C23.6091 20.9846 23.4454 21.2137 23.2402 21.402C23.035 21.5902 22.7927 21.7336 22.5289 21.8228C22.265 21.912 21.9855 21.9452 21.7081 21.9201C18.631 21.5857 15.6751 20.5342 13.0781 18.8501C10.6619 17.3148 8.61345 15.2663 7.07812 12.8501C5.38809 10.2413 4.33636 7.27109 4.00812 4.1801C3.98313 3.90356 4.01599 3.62486 4.10462 3.36172C4.19324 3.09859 4.33569 2.85679 4.52288 2.65172C4.71008 2.44665 4.93792 2.28281 5.19191 2.17062C5.44589 2.05843 5.72046 2.00036 5.99812 2.0001H8.99812C9.48342 1.99532 9.95391 2.16718 10.3219 2.48363C10.6898 2.80008 10.9302 3.23954 10.9981 3.7201C11.1247 4.68016 11.3596 5.62282 11.6981 6.5301C11.8327 6.88802 11.8618 7.27701 11.782 7.65098C11.7023 8.02494 11.517 8.36821 11.2481 8.6401L9.97812 9.9101C11.4017 12.4136 13.4746 14.4865 15.9781 15.9101L17.2481 14.6401C17.52 14.3712 17.8633 14.1859 18.2372 14.1062C18.6112 14.0264 19.0002 14.0556 19.3581 14.1901C20.2654 14.5286 21.2081 14.7635 22.1681 14.8901C22.6539 14.9586 23.0975 15.2033 23.4146 15.5776C23.7318 15.9519 23.9003 16.4297 23.8881 16.9201Z"
                stroke="#051D8D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_356_2400">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div>
            <h3>Phone Number</h3>
            <a href="#">(+20) 011-xxxx-xxxx-xxx</a>
          </div>
        </div> */}

        <div className="contact_i">
          <AnimationFadeLeft>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                stroke="#051D8D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 6L12 13L2 6"
                stroke="#051D8D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
          </AnimationFadeLeft>
          <AnimationFadeInUp>
            <div>
              <h3>Email Address</h3>
              <a href="mailto:admin@shasha.cloud"> admin@shasha.cloud</a>
            </div>{" "}
          </AnimationFadeInUp>
        </div>

        <div className="contact_i">
          <AnimationFadeLeft>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <g clip-path="url(#clip0_356_2408)">
                <path
                  d="M19.25 9.16675C19.25 15.5834 11 21.0834 11 21.0834C11 21.0834 2.75 15.5834 2.75 9.16675C2.75 6.97871 3.61919 4.88029 5.16637 3.33312C6.71354 1.78594 8.81196 0.916748 11 0.916748C13.188 0.916748 15.2865 1.78594 16.8336 3.33312C18.3808 4.88029 19.25 6.97871 19.25 9.16675Z"
                  stroke="#051D8D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 11.9167C12.5188 11.9167 13.75 10.6855 13.75 9.16675C13.75 7.64796 12.5188 6.41675 11 6.41675C9.48122 6.41675 8.25 7.64796 8.25 9.16675C8.25 10.6855 9.48122 11.9167 11 11.9167Z"
                  stroke="#051D8D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_356_2408">
                  <rect width="22" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </AnimationFadeLeft>
          <AnimationFadeInUp>
            <div>
              <h3>Location</h3>
              <p>
                10 9WC 523, Dubai Free-zone,
                <br />
                Dubai, UAE.
              </p>
            </div>
          </AnimationFadeInUp>
        </div>
      </div>
    </div>
  );
};
