import "../../styles/shared.scss";
import { AnimationFadeInUp } from "./Animations";

const CenterTitles = ({ title, description }) => {
  return (
    <>
      <div className="h_i_w_topInfo">
        <AnimationFadeInUp>
          <h2>{title}</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>{description}</p>
        </AnimationFadeInUp>
      </div>
    </>
  );
};

export default CenterTitles;
