import { Link } from "react-router-dom";
import Box from "./Box";
import { AnimationFadeIn, AnimationFadeInUp } from "./Animations";

const ImageTextSection = ({
  image,
  order,
  title,
  info,
  link1,
  link2,
  background,
  border,
}) => {
  return (
    <section style={{ backgroundColor: background }}>
      <AnimationFadeIn>
        <Box
          className={`${order == "left" ? "image_text_left" : ""}  image_text`}
        >
          <div className="i_t_text">
            <AnimationFadeInUp>
              <h2>{title}</h2>
            </AnimationFadeInUp>
            <AnimationFadeInUp>
              <p>{info}</p>
            </AnimationFadeInUp>
            <div className="i_t_links">
              {link1 && (
                <AnimationFadeInUp>
                  <Link to={link1.href}>
                    {link1.text}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M10.6787 3.86719L15.9287 9.11719M15.9287 9.11719L10.6787 14.3672M15.9287 9.11719L2.42871 9.11719"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </AnimationFadeInUp>
              )}
              {link2 && (
                <AnimationFadeInUp>
                  <Link
                    to={link2.href}
                    style={{ background: "#dadaff", color: "#051d8d" }}
                  >
                    {link2.text}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <path
                        d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9"
                        stroke="#051D8D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </AnimationFadeInUp>
              )}
            </div>
          </div>

          <div className={`i_t_img_text ${border && "backBorder"}`}>
            <div>
              <img src={image} alt="background" />
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="45"
                viewBox="0 0 50 45"
                fill="none"
              >
                <path
                  d="M39.3661 41.4655C41.5662 31.6328 43.752 21.8472 46.1786 12.0596C35.8948 20.8216 26.3231 31.0124 17.607 41.2846C20.198 28.5573 24.3214 16.1383 28.0331 3.70776C19.019 8.14568 11.4843 16.6002 4.15234 23.2208"
                  stroke="#8D8CC4"
                  stroke-width="6.44201"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg> */}
            </div>
          </div>
        </Box>
      </AnimationFadeIn>
    </section>
  );
};

export default ImageTextSection;
