import { Link, useSearchParams } from "react-router-dom";
import "../../styles/use-cases.scss";
import USECASE1 from "../../assets/images/use-case1.webp";
import USECASE2 from "../../assets/images/use-case2.webp";
import USECASE3 from "../../assets/images/education.jpg";
import USECASE4 from "../../assets/images/use-case4.webp";
import USECASE5 from "../../assets/images/use-case5.webp";
import USECASE6 from "../../assets/images/use-case6.webp";
import USECASE7 from "../../assets/images/use-case7.webp";
import USECASE8 from "../../assets/images/Media Owners.jpg";
import Box from "./Box";
import { AnimationFadeIn, AnimationFadeInUp } from "./Animations";

const list = [
  {
    id: "retail",
    title: "Malls and Retail",
    description:
      "Manage your product promotions, in-store advertisements, and special offers with ease. Our digital signage software helps you attract customers and drive sales with dynamic and eye-catching displays. Benefit from real-time updates to capitalize on timely opportunities.",
    img: USECASE1,
  },
  {
    id: "healthcare",
    title: "healthcare",
    description:
      "From displaying important announcements to guiding visitors, digital signage plays a crucial role in healthcare facilities. Our software makes it simple to manage informative displays, ensuring patients and staff are always well-informed. ",
    img: USECASE2,
  },
  {
    id: "education",
    title: "education",
    description:
      "Transform your school or university communication with digital signage. Share announcements, event schedules, and educational content in an engaging and eco-friendly way. Our software simplifies content management, making it easy even for non-tech-savvy staff.",
    img: USECASE3,
  },
  {
    id: "restaurants",
    title: "F&B",
    description:
      "Create mouth-watering digital menus and promote your specials with our easy-to-use software. Update pricing, items, or nutritional information in real-time across multiple locations. Our solution is designed to save you time and attract more diners.",
    img: USECASE4,
  },
  {
    id: "corporate",
    title: "Banks and Corporates",
    description:  
      "Improve your internal communication with dynamic digital signage. Share company news, recognize employee achievements, and display performance metrics with our software. Foster a transparent, engaging, and productive workplace environment.",
    img: USECASE5,
  },
  {
    id: "transportation",
    title: "transportation",
    description:
      "From airports to subway stations, digital signage is critical for sharing real-time information. Our software enables easy content management, ensuring passengers are always updated about schedules, delays, and safety information.",
    img: USECASE6,
  },
  {
    id: "MediaOwner",
    title: "Media Owners",
    description:
      "Manage your indoor and outdoor space with a simple and reliable online platform. Our digital signage software helps you attract customers and drive sales with dynamic and eye-catching displays. Benefit from real-time updates to capitalize on timely opportunities.",
    img: USECASE8,
  },
  {
    id: "hospitality",
    title: "hospitality",
    description:
      "Make your guests feel welcomed and informed with engaging digital signage. Promote your services, share local attractions, or display event schedules. Our software helps you create a personalized experience for your guests.",
    img: USECASE7,
  },
];

export const UseCasesList = () => {
  return (
    <>
      <SortUseCases items={list} />
    </>
  );
};

const SortUseCases = ({ items }) => {
  return (
    <>
      <section className="use_cases_list">
        {items &&
          items.map((item) => (
            <div key={item.id} className="use_cases_list_item">
              <AnimationFadeInUp>
                <h2>{item.title}</h2>
              </AnimationFadeInUp>
              <AnimationFadeInUp>
                <p>
                  {/* {item.description.split(" ").slice(0, 15).join(" ") + ". . ."} */}
                  {item.description}
                </p>
              </AnimationFadeInUp>

              <AnimationFadeIn>
                <img src={item.img} alt={item.title} />
              </AnimationFadeIn>
              {/* <AnimationFadeInUp>
                <Link to={`/use-cases-details/?id=${item.id}`}>
                  Read More{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="8"
                    viewBox="0 0 25 8"
                    fill="none"
                  >
                    <path
                      d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z"
                      fill="#051D8D"
                    />
                  </svg>
                </Link>
              </AnimationFadeInUp> */}
            </div>
          ))}{" "}
      </section>
    </>
  );
};

export const UseCasesDetailsItem = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const item = searchParams.get("id");
  let itemIndex = 0;
  const itemDetails = list.filter((x, i) => {
    if (x.id === item) {
      itemIndex = i;
      return x;
    }
    return false;
  })[0];

  const suggestionList = [...list, list[0], list[1]].filter(
    (x, i) => i > itemIndex
  );

  return (
    <>
      <Box className="use_cases_details">
        <div className="use_cases_details_back">
          <AnimationFadeInUp>
            <Link to={"/use-cases"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="8"
                viewBox="0 0 18 8"
                fill="none"
              >
                <path
                  d="M0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM18 4.5H1V3.5H18V4.5Z"
                  fill="black"
                />
              </svg>{" "}
              View all Use Cases
            </Link>
          </AnimationFadeInUp>
        </div>
        <section className="use_cases_details_item">
          <div className="use_cases_details_item_img">
            <AnimationFadeIn>
              <img src={itemDetails.img} alt={itemDetails.title} />
            </AnimationFadeIn>
          </div>
          <AnimationFadeInUp>
            <h2>{itemDetails.title}</h2>
          </AnimationFadeInUp>
          <AnimationFadeInUp>
            <p>{itemDetails.description}</p>
          </AnimationFadeInUp>
        </section>
        <Box>
          <div className="use_cases_details_back">
            <AnimationFadeInUp>
              <p>More Use Cases</p>
            </AnimationFadeInUp>
          </div>
          <SortUseCases items={[suggestionList[0], suggestionList[1]]} />
        </Box>{" "}
      </Box>
    </>
  );
};

export const UseCasesSlide = () => {
  return (
    <Box className="use_cases_slide">
      <div className="use_cases_slide_top">
        <AnimationFadeInUp>
          <h2>Key Industries</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <div className="use_cases_slide_all">
            <p>
              From small neighborhood stores to global corporations, we believe
              in the power of communication and its potential to transform
              businesses. Our digital signage content management software has
              been meticulously designed to deliver that power into your hands.
            </p>{" "}
            {/* <Link to="/use-cases" className="light_link">
              See All
            </Link> */}
          </div>
        </AnimationFadeInUp>
      </div>
      <AnimationFadeIn>
        <div className="use_cases_slide_bottom">
          {list.map((i) => (
            <div key={i.id} className="use_cases_slide_item">
              <img src={i.img} alt={i.title} />
              <div>
                <h3>{i.title}</h3>
                <p>
                  {/* {i.description.split(" ").slice(0, 15).join(" ") + ". . ."} */}
                  {i.description}
                </p>
                {/* <Link to={`/use-cases-details/?id=${i.id}`}>More Details</Link> */}
              </div>
            </div>
          ))}
        </div>
      </AnimationFadeIn>
      <br />
      <br />
      <AnimationFadeInUp>
        <div
          style={{
            margin: "auto",
            width: "90%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link to="/use-cases" className="light_link">
            See All
          </Link>
        </div>
      </AnimationFadeInUp>
    </Box>
  );
};
