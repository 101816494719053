// import { useSelector } from "react-redux";
import BACK3 from "../assets/images/homeimage3.jpg";
import BACK2 from "../assets/images/pexels-benoit-dujardin-80608394-8756809.jpg";
import HowItWorksSection from "../components/shared/HowItWorksSection";
import ImageTextSection from "../components/shared/ImageTextSection";
import PageTitle from "../components/shared/PageTitle";

const HowItWorks = () => {
  // const { content } = useSelector((state) => state.content);
  return (
    <>
      {/* <PageTitle title={"How It Works?"} subTitle={" "} /> */}
      <div className="how_it">
        <ImageTextSection
          background="#fff"
          title={"How It Works"}
          info={
            "Here at Shasha, we believe that managing your digital signage should be as straightforward and reliable as possible. Our platform is designed with this principle at its core. Here's how it works:  "
          }
          // link1={{ href: "/contact", text: "Read More" }}
          image={BACK3}
          border={false}
        />
      </div>
      <HowItWorksSection
        title={`Unleashing the Power of Digital Signage Made Simple`}
        info={``}
      />
      <div className="how_bottom">
        <ImageTextSection
          background="#ededed"
          title={"Get Started"}
          info={
            "Our digital signage content management software has been meticulously designed to deliver that power into your hands. "
          }
          link1={{ href: "/contact", text: "Start Your Free Trial Today" }}
          link2={{ href: "/contact", text: "Request a Demo" }}
          image={BACK2}
          border={true}
        />
      </div>
    </>
  );
};

export default HowItWorks;
