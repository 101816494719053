import { Link } from "react-router-dom";
import Box from "./Box";
import { AnimationFadeInUp } from "./Animations";

const TryDemoBottom = ({ content, background }) => {
  return (
    <section className={`${background && "try_bottom_back"}`}>
      <Box
        className={`try_bottom ${background && "try_bottom_back"} page_title`}
      >
        <h1>Get Started</h1>
        <AnimationFadeInUp>
          <p>{content}</p>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <div className="try_links">
            <Link to="/contact">
              Try For Free
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>

            <Link to="/contact">
              Request a Demo
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <path
                  d="M11.3213 3.75L16.5713 9M16.5713 9L11.3213 14.25M16.5713 9L3.07129 9"
                  stroke="#051D8D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
        </AnimationFadeInUp>
      </Box>
    </section>
  );
};

export default TryDemoBottom;
