import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import "../styles/players.scss";
import PLAYER1 from "../assets/images/player3.webp";
import PLAYER2 from "../assets/images/player1.webp";
import PLAYER3 from "../assets/images/player4.webp";
import PLAYER4 from "../assets/images/player5.webp";
import PLAYER5 from "../assets/images/player2.webp";
import PLAYER6 from "../assets/images/player7.webp";
import PLAYER7 from "../assets/images/player6.webp";
import PLAYER8 from "../assets/images/brightsign-logo.webp";
import PLAYER9 from "../assets/images/embed-signage-digital-signage-software-supported-devices-samsung-smart-signage-platform.png";
import PLAYER10 from "../assets/images/lg-webos-new.svg";
import PLAYER11 from "../assets/images/Philips_PPDS_label_rgb-525x360.png";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const Players = () => {
  return (
    <>
      <PageTitle
        title={"Players"}
        // subTitle={"Home - Features - Players"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <p style={{ color: "#000" }}>
            In our quest to elevate our client's experience, Shasha takes pride
            in our enduring alliance with Onsign.tv. Since 2021, this
            collaboration has been instrumental in harnessing the power of their
            Player App to enrich the digital signage journey for our clients.
            Our dedication to crafting solutions specifically for the MENA
            region, coupled with the innovative prowess of Onsign.tv, empowers
            us to deliver distinctive and market-leading solutions.
          </p>

          <br />

          <p style={{ color: "#000" }}>
            In our commitment to providing a seamless and inlcusive
            userevperience, our digital signage content management software is
            designed for versatility ensuring flawless integration with a
            diverse range of operating systems, including:
          </p>
        </AnimationFadeInUp>
      </Box>

      <div className="players_list">
        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER4} style={{ width: "50%" }} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>Windows</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href=" https://app.onsign.tv/download/player/windows/ ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download
            </a>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER6} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>MacOS</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href="https://app.onsign.tv/download/player/mac/ ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download
            </a>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER7} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>Linux</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href=" https://app.onsign.tv/download/player/linux/ ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download
            </a>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER1} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>Chrome OS</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href="https://docs.onsign.tv/articles/#!en/chrome-os ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download App
            </a>
          </AnimationFadeInUp>
        </div>

        {/* <div className="players_item">
          <img src={PLAYER5} alt="player" />
          <h3>iOS</h3>
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
            >
              <g clip-path="url(#clip0_334_1550)">
                <path
                  d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                  fill="#051D8D"
                />
              </g>
              <defs>
                <clipPath id="clip0_334_1550">
                  <rect
                    width="19"
                    height="19"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>{" "}
            Download
          </a>
        </div> */}

        {/* <div className="players_item">
          <img src={PLAYER3} alt="player" />
          <h3>Web-based</h3>
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
            >
              <g clip-path="url(#clip0_334_1550)">
                <path
                  d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                  fill="#051D8D"
                />
              </g>
              <defs>
                <clipPath id="clip0_334_1550">
                  <rect
                    width="19"
                    height="19"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>{" "}
            Download
          </a>
        </div> */}

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER8} style={{ width: "50%" }} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>BrightSign</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href="https://docs.onsign.tv/articles/#!en/installing-brightsign-player-software ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download
            </a>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER9} style={{ width: "50%" }} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>
            {/* <h3>Samsung SSP and Tizen</h3> */}
          </AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href="https://docs.onsign.tv/articles/#!en/samsung-sssp-and-tizen ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download
            </a>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <br />
          <AnimationFadeInUp>
            <img src={PLAYER10} style={{ width: "50%" }} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>LG webOS:</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <a href="https://docs.onsign.tv/articles/#!en/installing-on-webos-20-to-32 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <g clip-path="url(#clip0_334_1550)">
                  <path
                    d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                    fill="#051D8D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_1550">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Download
            </a>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER2} style={{ width: "50%" }} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>Android</h3> */}</AnimationFadeInUp>

          <AnimationFadeInUp>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <a href="https://onsign.tv/spm ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_334_1550)">
                    <path
                      d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                      fill="#051D8D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_334_1550">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Download App
              </a>

              <a href="https://app.onsign.tv/download/player/latest/android/ ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_334_1550)">
                    <path
                      d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                      fill="#051D8D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_334_1550">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Download SPM
              </a>
            </div>
          </AnimationFadeInUp>
        </div>

        <div className="players_item">
          <AnimationFadeInUp>
            <img src={PLAYER11} style={{ width: "50%" }} alt="player" />
          </AnimationFadeInUp>
          <AnimationFadeInUp>{/* <h3>Philips</h3> */}</AnimationFadeInUp>
          <AnimationFadeInUp>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <a href=" https://app.onsign.tv/download/player/latest/android/  ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_334_1550)">
                    <path
                      d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                      fill="#051D8D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_334_1550">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Download App
              </a>
              <a href="https://onsign.tv/spm ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_334_1550)">
                    <path
                      d="M15.8333 12.3752C16.2706 12.3752 16.625 12.7296 16.625 13.1668V16.3335C16.625 17.208 15.9161 17.9168 15.0417 17.9168H3.95833C3.08388 17.9168 2.375 17.208 2.375 16.3335V13.1668C2.375 12.7296 2.72945 12.3752 3.16667 12.3752C3.60389 12.3752 3.95833 12.7296 3.95833 13.1668V16.3335H15.0417V13.1668C15.0417 12.7296 15.3961 12.3752 15.8333 12.3752ZM9.5 2.0835C9.93724 2.0835 10.2917 2.43794 10.2917 2.87516V10.9839L12.2989 8.97662C12.6082 8.66739 13.1094 8.66739 13.4185 8.97662C13.7277 9.28576 13.7277 9.78705 13.4185 10.0962L10.1998 13.3149C9.81326 13.7014 9.18674 13.7014 8.80025 13.3149L5.58145 10.0962C5.27229 9.78705 5.27229 9.28576 5.58145 8.97662C5.89062 8.66739 6.39187 8.66739 6.70104 8.97662L8.70833 10.9839V2.87516C8.70833 2.43794 9.06276 2.0835 9.5 2.0835Z"
                      fill="#051D8D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_334_1550">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Download SPM
              </a>
            </div>
          </AnimationFadeInUp>
        </div>

        {/* <p
          style={{
            textAlign: "center",
            fontSize: "1.25rem",
            width: "100vw",
            marginBottom: "30px",
          }}
        >
          This compatibility underscores our commitment to providing a seamless
          and inclusive user experience across all devices.
        </p> */}
        <br />
      </div>

      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};

export default Players;
