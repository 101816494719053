import "../../styles/shared.scss";
import { AnimationFadeInUp } from "./Animations";
import Box from "./Box";

const PageTitle = ({ title, subTitle, bt }) => {
  return (
    <>
      <Box className="page_title">
        <AnimationFadeInUp>
          <h1>{title}</h1>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>{subTitle}</p>
        </AnimationFadeInUp>
        {bt && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
      </Box>
    </>
  );
};

export default PageTitle;
