import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import ListSquareItems from "../components/shared/ListSquareItems";
import CHECK from "../assets/icons/check-7091.svg";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { AnimationFadeInUp } from "../components/shared/Animations";

const list = [
  {
    id: "Scheduled Publishing ",
    title: "Scheduled Publishing ",
    img: CHECK,
    imageType: "gif",
    info: `Plan and schedule your content in advance. Set specific dates and times for content to automatically go live, ensuring your messages are delivered precisely when you want them to be. `,
  },
  {
    id: " Instant Publishing",
    title: " Instant Publishing",
    img: CHECK,
    imageType: "gif",
    info: `Need to push out urgent updates or time-sensitive information? With our instant publishing feature, you can quickly deploy content to your screens with just a few clicks, ensuring real-time communication. `,
  },
  {
    id: "Expiration Dates ",
    title: "Expiration Dates ",
    img: CHECK,
    imageType: "gif",
    info: ` Set expiration dates for your content to automatically unpublish at a designated time. Maintain content freshness and avoid outdated information being displayed on your screens.`,
  },
];

const PublishingUnpublishing = () => {
  return (
    <>
      <PageTitle
        title={"Content Publishing/Unpublishing"}
        // subTitle={"Home - Features - Content Publishing/Unpublishing"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <h2>Effortless Control Over Content Deployment</h2>
        </AnimationFadeInUp>
        <AnimationFadeInUp>
          <p>
            With our content management software, publishing and unpublishing
            content is a breeze. Take full control over the timing and
            availability of your digital signage displays:
          </p>
        </AnimationFadeInUp>
      </Box>
      <ListSquareItems list={list} icons={true} rowNum={3} />
      {/* <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      /> */}
    </>
  );
};
export default PublishingUnpublishing;
