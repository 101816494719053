import Box from "../components/shared/Box";
import PageTitle from "../components/shared/PageTitle";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import { UseCasesList } from "../components/shared/UseCasesSections";

const UseCases = () => {
  return (
    <>
      <PageTitle
        title={"Key Industries"}
        // subTitle={"Home - Key Industries"}
      />
      <Box>
        <UseCasesList />
      </Box>
      <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
`}
        background={true}
      />
    </>
  );
};

export default UseCases;
