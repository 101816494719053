import { useState } from "react";
import { Link } from "react-router-dom";
import { AnimationFadeIn, AnimationFadeLeft } from "./Animations";

const FeaturesList = ({ list, allLink }) => {
  const [featurePopup, setFeaturePopup] = useState({});

  return (
    <section className="features_list">
      {/* {featurePopup.title && (
        <section className="feature_popup">
          <div className="feature_popup_item  ">
            <div className="feature_popup_item_top">
              <div className="feature_popup_item_top_con">
                <img src={featurePopup.img} alt={featurePopup.title} />

                <h3>{featurePopup.title}</h3>
              </div>
              <svg
                onClick={() => setFeaturePopup({})}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_334_760)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0007 13.4144L17.6575 19.0713C18.048 19.4618 18.6812 19.4618 19.0717 19.0713C19.4622 18.6807 19.4622 18.0476 19.0717 17.657L13.4149 12.0002L19.0717 6.34335C19.4622 5.95283 19.4622 5.31966 19.0717 4.92914C18.6812 4.53861 18.048 4.53861 17.6575 4.92914L12.0007 10.586L6.34382 4.92912C5.9533 4.5386 5.32013 4.5386 4.92961 4.92912C4.53909 5.31965 4.53909 5.95281 4.92961 6.34334L10.5864 12.0002L4.92961 17.6571C4.53909 18.0476 4.53909 18.6807 4.92961 19.0713C5.32013 19.4618 5.9533 19.4618 6.34382 19.0713L12.0007 13.4144Z"
                    fill="#010101"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_334_760">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p>{featurePopup.full_description}</p>
          </div>
        </section>
      )} */}

      {list &&
        list.map((item) => (
          <div key={item.id} className="features_list_item">
            <div className="feature_item_img_con">
              <AnimationFadeLeft>
                <div className="feature_item_img">
                  <img src={item.img} alt={item.title} />
                </div>{" "}
              </AnimationFadeLeft>
            </div>
            <AnimationFadeIn>
              <div className="feature_item_text">
                <h3>{item.title}</h3>
                <p>{item.full_description}</p>
                {/* <button onClick={() => setFeaturePopup(item)}>
                  LEARN MORE{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M5.746 1.39453H3.8765C2.339 1.39453 1.375 2.48303 1.375 4.02403V8.18103C1.375 9.72203 2.3345 10.8105 3.8765 10.8105H8.2885C9.831 10.8105 10.7905 9.72203 10.7905 8.18103V6.16703"
                      stroke="#051D8D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.9339 1.03097L6.67678 5.2881"
                      stroke="#051D8D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.86719 1.03516L10.9334 1.03091L10.9291 3.09708"
                      stroke="#051D8D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button> */}
              </div>
            </AnimationFadeIn>
          </div>
        ))}

      {allLink && (
        <div className="feature_all_link features_list_item">
          <AnimationFadeIn>
            <Link to="/key-features">
              Show All Features{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M14.4297 6.42999L20.4997 12.5L14.4297 18.57"
                  stroke="#051D8D"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.5 12.5H20.33"
                  stroke="#051D8D"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </AnimationFadeIn>
        </div>
      )}
    </section>
  );
};

export default FeaturesList;
