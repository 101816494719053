import { createSlice } from "@reduxjs/toolkit";

const contentSlice = createSlice({
  name: "content",
  initialState: {
    content: [],
  },
  reducers: {
    addContent: (state, action) => {
      state.content = action.payload;
    },
  },
});

export default contentSlice;
