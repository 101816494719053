import Box from "../components/shared/Box";
import FeaturesList from "../components/shared/FeaturesList";
import PageTitle from "../components/shared/PageTitle";
import FEATURE_GREEN from "../assets/icons/feature_Green.webp";
import FEATURE_BLUE from "../assets/icons/feature_Blue.webp";
import FEATURE_ORANGE from "../assets/icons/feature_Orange.webp";
import FEATURE_TEAL from "../assets/icons/feature_Teal.webp";
import FEATURE_YELLOW from "../assets/icons/feature_Yellow.webp";
import FEATURE_RED from "../assets/icons/feature_Red.webp";
import TryDemoBottom from "../components/shared/TryDemoBottom";
import Players from "./Players";
import AppsPage from "./AppsPage";
import Content from "./Content";
import Reports from "./Reports";
import Security from "./Security";
import Integrations from "./Integrations";
import CloudInfrastructure from "./CloudInfrastructure";
import MonitoringManagement from "./MonitoringManagement";
import Interactivity from "./Interactivity";
import PublishingUnpublishing from "./PublishingUnpublishing";
import GeoTagging from "./GeoTagging";
import { AnimationFadeInUp } from "../components/shared/Animations";

const items = [
  {
    id: "Drag-and-Drop Content Editor",
    title: "Drag-and-Drop Content Editor",
    description: "Effortlessly design stunning displays with..",
    full_description:
      "Effortlessly design stunning displays with our intuitive drag-and-drop editor. No coding or design skills required. Customize layouts, add text, images, videos, and even social media feeds to create visually captivating content.",
    img: FEATURE_GREEN,
  },
  {
    id: "Real-Time Updates",
    title: "Real-Time Updates",
    description: "Keep your content fresh ..",
    full_description:
      "Keep your content fresh and relevant with real-time updates. Make instant changes to your displays from anywhere, ensuring your messages are always up to date and aligned with your business needs.",
    img: FEATURE_BLUE,
  },
  {
    id: "Multi-Platform Compatibility",
    title: "Multi-Platform Compatibility",
    description: "Our software supports a wide ..",
    full_description:
      "Our software supports a wide range of hardware, giving you the flexibility to choose the player that fits your requirements and budget. Easily integrate your preferred hardware and enjoy seamless functionality.",
    img: FEATURE_ORANGE,
  },
  {
    id: "Scheduling & Targeting",
    title: "Scheduling & Targeting",
    description: "Deliver the right message ..",
    full_description:
      "Deliver the right message to the right audience at the right time. Utilize our advanced scheduling and targeting features to display content based on specific dates, times, or locations. Tailor your messages for maximum impact.",
    img: FEATURE_YELLOW,
  },
  {
    id: "Remote Monitoring & Control",
    title: "Remote Monitoring & Control",
    description: "Monitor and manage your ..",
    full_description:
      "Monitor and manage your screens remotely with ease. Our software allows you to oversee content playback, screen status, and troubleshoot issues from a centralized dashboard, saving you time and effort.",
    img: FEATURE_TEAL,
  },
  /*------------------------------*/
  {
    id: "Interactive Touchscreen Support",
    title: "Interactive Touchscreen Support",
    description: "Engage your audience with ..",
    full_description:
      "Engage your audience with interactive experiences using touchscreens. Create interactive maps, product catalogs, surveys, and more, allowing viewers to actively engage with your content and increase their involvement.",
    img: FEATURE_TEAL,
  },
  {
    id: "Analytics and Reports",
    title: "Analytics and Reports",
    description: "Measure the success of your ..",
    full_description:
      "Measure the success of your campaigns and gain valuable insights into audience behavior. Our robust analytics and reporting features provide comprehensive data on content performance, audience engagement, and more.",
    img: FEATURE_YELLOW,
  },
  {
    id: "Templates and Layouts",
    title: "Templates and Layouts",
    description: "Save time and streamline your ..",
    full_description:
      "Save time and streamline your content creation process with our collection of pre-designed templates and layouts. Choose from a variety of professionally designed options, customize them to fit your brand, and publish in minutes.",
    img: FEATURE_BLUE,
  },
  {
    id: "Media Library",
    title: "Media Library",
    description: "Organize and manage your ..",
    full_description:
      "Organize and manage your media assets efficiently with our media library. Store images, videos, and other multimedia files in a central location, making it easy to access and reuse content whenever needed.",
    img: FEATURE_YELLOW,
  },
  {
    id: "Weather Integration",
    title: "Weather Integration",
    description: "Display real-time weather ..",
    full_description:
      "Display real-time weather updates to provide your audience with relevant information and create a more engaging experience. Easily integrate weather data into your content to keep viewers informed.",
    img: FEATURE_BLUE,
  },
  {
    id: "Social Media Integration",
    title: "Social Media Integration",
    description: "Harness the power of ..",
    full_description:
      "Harness the power of social media by seamlessly integrating your feeds into your digital signage displays. Keep your audience up to date with your latest social media posts, customer reviews, and more.",
    img: FEATURE_ORANGE,
  },
  {
    id: "Emergency Alerts",
    title: "Emergency Alerts",
    description: "Ensure the safety of your ..",
    full_description:
      "Ensure the safety of your audience by using our emergency alert feature. Instantly push critical information and emergency notifications to your screens in times of crisis or unexpected events.",
    img: FEATURE_BLUE,
  },
  {
    id: "Multi-Language Support ",
    title: "Multi-Language Support ",
    description: "Reach a diverse audience ..",
    full_description:
      "Reach a diverse audience by displaying content in multiple languages. Our software supports a wide range of languages, allowing you to communicate effectively with viewers from different backgrounds.",
    img: FEATURE_GREEN,
  },
  {
    id: "Multi-Zone Layouts",
    title: "Multi-Zone Layouts",
    description: "Maximize the impact of your ..",
    full_description:
      "Maximize the impact of your displays by creating multi-zone layouts. Divide the screen into multiple sections to showcase different content simultaneously, providing a dynamic and versatile viewing experience.",
    img: FEATURE_YELLOW,
  },
  {
    id: "Centralized Content Management",
    title: "Centralized Content Management",
    description: "Effortlessly manage and update ..",
    full_description:
      "Effortlessly manage and update content across all your screens from a centralized dashboard. Control multiple displays, locations, and campaigns simultaneously, saving time and ensuring consistency.",
    img: FEATURE_TEAL,
  },
  {
    id: "Live Video Streaming",
    title: "Live Video Streaming",
    description: "Stream live videos or broadcasts ..",
    full_description:
      "Stream live videos or broadcasts directly on your digital signage screens. Share live events, webinars, or conferences, allowing your audience to stay connected and engaged in real-time.",
    img: FEATURE_ORANGE,
  },
  {
    id: "Content Approval Workflow",
    title: "Content Approval Workflow",
    description: "Maintain control over your ..",
    full_description:
      "Maintain control over your content by implementing an approval workflow. Ensure consistency and quality by having content reviewed and approved by designated team members before it goes live.",
    img: FEATURE_BLUE,
  },
  {
    id: "User Access Control",
    title: "User Access Control",
    description: "Manage user roles and permissions ..",
    full_description:
      "Manage user roles and permissions to control access to specific features and functionalities within the platform. Safeguard your content and ensure only authorized personnel can make changes.",
    img: FEATURE_YELLOW,
  },
  {
    id: "Offline Playback",
    title: "Offline Playback",
    description: "Ensure uninterrupted playback ..",
    full_description:
      "Ensure uninterrupted playback even in the event of a network interruption. Our software supports offline playback, allowing your screens to continue displaying content seamlessly.",
    img: FEATURE_GREEN,
  },
  {
    id: "Training and Support",
    title: "Training and Support",
    description: "Receive comprehensive training ..",
    full_description:
      "Receive comprehensive training and ongoing support from our dedicated team. We're here to assist you with onboarding, troubleshooting, and providing guidance to help you make the most of our software.",
    img: FEATURE_RED,
  },
];

const Features = () => {
  return (
    <div id="key-features">
      <div></div>

      <PageTitle
        title={"Features"}
        // subTitle={"Home - Features - Key Features"}
      />

      <Box className="page_description">
        <AnimationFadeInUp>
          <p>
            Our feature-rich digital signage content management software is
            designed to empower businesses of all sizes. Whether you're a small
            mom and pop shop or a global corporation, we have the tools you need
            to create engaging and impactful displays. Here are some of the key
            features that set us apart:
          </p>
        </AnimationFadeInUp>
      </Box>

      <FeaturesList list={items} />

      <div id="apps"></div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <AppsPage />
      <div id="content"></div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <Content />
      <div id="reports"></div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <Reports />
      <div id="security"> </div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <Security />
      <div id="api-integrations"> </div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <Integrations />
      <div id="cloud-infrastructure"> </div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <CloudInfrastructure />
      <div id="remote-player-monitoring-and-management"></div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <MonitoringManagement />
      <div id="interactivity"> </div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <Interactivity />
      <div id="content-publishing-unpublishing"> </div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <PublishingUnpublishing />
      <div id="geo-tagging"></div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <GeoTagging />

      <TryDemoBottom
        content={`With these features and more, Shasha provides a complete digital signage content management solution. Start your free trial or request a demo today to explore the full range of capabilities and see how we can elevate your digital signage experience!
    `}
        background={true}
      />
    </div>
  );
};

export default Features;
